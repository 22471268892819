import './App.css';
import image1 from './assets/logo.png';
import image2 from './assets/opensea.00030618.svg';
import image3 from './assets/Esf2Etq.png';
import image4 from './assets/uQXk1BC.png';
import image5 from './assets/1516283144552.jpg';
import image6 from './assets/WhatsApp_Image_2022-02-10_at_19.06.07.jpeg';
import image7 from './assets/e2c356dc-dbc3-4fb0-8b8a-bae0d553d970.jpg';
import image8 from "./assets/WhatsApp_Image_2022-02-10_at_19.16.13.jpeg";
import image9 from "./assets/WhatsApp_Image_2022-02-10_at_14.56.38.jpeg";
import image10 from "./assets/WhatsApp_Image_2022-02-10_at_14.54.11.jpeg";
import image11 from "./assets/background-legendary.18152d8c.png";
import image12 from "./assets/partners.png";
import image13 from "./assets/soz5Kx5.png";
import image14 from "./assets/Group_3.png";
import image15 from "./assets/Group_629.png";
import image16 from "./assets/Hf1q1y4.png";
import image17 from "./assets/Legendaries_3.png";
import image18 from "./assets/Legendaries_4.png";
import image19 from "./assets/Legendaries_5.png";
import image20 from "./assets/BM6rCxS.png";
import image21 from "./assets/XQscJCJ.png";
import image22 from "./assets/CU7Znsl.png";
import image23 from "./assets/g8ltSMt.png";
import image24 from "./assets/orange_juice.png";
import image25 from "./assets/digitaljeff.png";
import image26 from "./assets/ben_murray.png";
import image27 from "./assets/carlos_ramirez.png";
import image28 from "./assets/dcarlo_calderon.png";
import image29 from "./assets/paul_mcgee.png";
import image30 from "./assets/rolando_sanchez.png";
import image31 from "./assets/ojalbum1.png";
import image32 from "./assets/ojalbum2.png";
import image33 from "./assets/ojalbum3.png";
import image34 from "./assets/ojalbum4.png";
import image35 from "./assets/ojalbum5.png";
import image36 from "./assets/ojalbum6.png";
import image37 from "./assets/ojalbum7.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from 'react';
import arrowLeft from "./assets/arrow-left.d66bd39f.svg";
import arrowRight from "./assets/arrow-right.17ae4fb2.svg";
import image38 from "./assets/Orange Juice_TeamPic.png";
import image39 from "./assets/Digitaljeff_Teampic.jpeg";
import image40 from "./assets/CarlosRamirez.jpg";
import image41 from "./assets/Dcarlo_calderon_teampic.png";
import image42 from "./assets/Paulmcgee_teampic.jpg";
import image43 from "./assets/rolandosanchez_teampic.jpg";

function App() {
  let [oldSlide, setOldSlide] = useState(0)
  let [activeSlide, setActiveSlide] = useState(0)
  let [activeSlide2, setActiveSlide2] = useState(0)

  useEffect(() => {
    document.querySelector('.legends_slider__wrap .pre-arrow').addEventListener('click', el => {
      document.querySelector('.legends_slider__wrap .slick-prev').click();
    });

    document.querySelector('.legends_slider__wrap .next-arrow').addEventListener('click', el => {
      document.querySelector('.legends_slider__wrap .slick-next').click();
    });
  }, [])
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    speed: 500,
    arrows: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    beforeChange: (current, next) => {
      setOldSlide(current);
      setActiveSlide(next)
    },
    afterChange: (current) => {
      setActiveSlide2(current)
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  let faqButton2 = (e) => {
    var y = document.querySelector(`#${e.currentTarget.id}`);
    y.classList.toggle("gradient-about")
  }
  let faqButton1 = (e) => {
    var y = document.querySelector(`#${e.currentTarget.id}`);
    let siblings = y.parentElement.children;
    var z = y.classList.contains('mystyle-faq-1')
    for (let sib of siblings) {
      console.log(sib)
      sib.classList.toggle('mystyle-faq-1')
      var w = sib.querySelector('svg path');
      if (w !== null) {
        console.log(w)
        if (z == false) {
          w.setAttribute("d", "M20 12H4")
        } else {
          w.setAttribute("d", "M12 4v16m8-8H4")
        }
      }
    }
  }
  let navMobile = () => {
    document.querySelector("div#headlessui-disclosure-panel-2").classList.toggle("navToMob")
    // var y = document.querySelector("headlessui-disclosure-panel-2");
    // let siblings = y.parentElement.children;
    var y = document.querySelector("#headlessui-disclosure-button-1 svg path");
    var z = document.querySelector("div#headlessui-disclosure-panel-2").classList.contains('navToMob')
console.log(z)
if (z) {
  y.setAttribute("d", "M4 6h16M4 12h16M4 18h16")
} else {
  y.setAttribute("d", "M6 18L18 6M6 6l12 12")
}
    // var z = y.classList.contains('navToMob')
    // for (let sib of siblings) {
    //   console.log(sib)
    //   sib.classList.toggle('navToMob')
    //   var w = sib.querySelector('svg path');
    //   if (w !== null) {
    //     console.log(w)
    //     if (z == false) {
    //       w.setAttribute("d", "M20 12H4")
    //     } else {
    //       w.setAttribute("d", "M12 4v16m8-8H4")
    //     }
    //   }
    // }
  }
  return (
    <div id="root">
      <div className="bg-black">
        <div className="bg-bar pt-2 pb-1">
          <div className=" marquee">
            <div className="marquee-content">
              <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
              <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
              <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
              <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
              <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
            </div>
          </div>
        </div>
        <div>
          <div>
            <nav className="bg-black shadow-2xl">
              <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <div className="relative flex items-center justify-between h-20">
                  <div className="hidden lg:block w-1/3 flex items-center px-2 lg:px-0">
                    <div className="">
                      <div className="flex space-x-2" style={{ position: "relative" }}>
                        <a href="/#features"
                            className="text-white px-3 py-2 rounded-md text-xs font-bold uppercase">Features</a>
                        <a href="/#artist" className="text-white px-3 py-2 rounded-md text-xs font-bold uppercase">Artist</a>
                        <a href="/#roadmap"
                            className="text-white px-3 py-2 rounded-md text-xs font-bold uppercase">Roadmap</a>
                        <a href="/#faq"
                            className="text-white px-3 py-2 rounded-md text-xs font-bold uppercase">FAQ</a>
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto w-1/3 flex items-center justify-center px-2 lg:px-0">
                    <div className="flex-shrink-0"><a href="/"><img className="h-14 w-auto" src={image1} alt="" /></a>
                    </div>
                  </div>
                  <div className="flex lg:hidden absolute"><button onClick={navMobile}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    id="headlessui-disclosure-button-1" type="button" aria-expanded="false"><span className="sr-only">Open
                      main menu</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true" className="block h-6 w-6">
                      <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16">
                      </path>
                    </svg></button></div>
                  <div className="w-1/3 hidden lg:block">
                    <div className="flex items-center gap-1"><a href="https://www.instagram.com/orangejuice_pr/"
                      className="rounded-full p-1" target="_blank" rel="noreferrer"><img height="16" width="16"
                        src="https://i.imgur.com/cBOGvIP.png" alt="" /></a><a href="https://twitter.com/orangejuicehere/"
                          className="rounded-full p-1" target="_blank" rel="noreferrer"><img height="16" width="16"
                            src="https://i.imgur.com/dC7qngW.png" alt="" /></a>
                      <div className="ml-2 bg-pink w-60 mx-auto"><a href="https://discord.gg/FX2cSvQ36h" target="_blank"
                        rel="noreferrer"
                        className="uppercase text-xs tracking-wider h-10 px-6 flex justify-center items-center text-white"><span>Join
                          us on discord</span></a></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="lg:hidden navToMob" id="headlessui-disclosure-panel-2">
                <div class="px-2 pt-2 pb-3 space-y-1">
              <a href="/#features" class="text-white block px-3 py-2 rounded-md text-base font-medium">Features</a>
              <a href="/#artist" class="text-white block px-3 py-2 rounded-md text-base font-medium">Artist</a>
              <a href="/#roadmap" class="text-white block px-3 py-2 rounded-md text-base font-medium">Roadmap</a>
              <a href="/#faq" class="text-white block px-3 py-2 rounded-md text-base font-medium">FAQ</a>
                </div>
              </div>

            </nav>
            <div className="h-0.5 w-full gradient-nav"></div>
          </div>
          <div className="relative">
            <img height="1640" src={image3} alt="" className="hidden sm:block w-full  object-cover z-10" />
            <img height="2266" src={image4} alt="" className="sm:hidden w-full object-cover z-10" />
            <div className="absolute top-10 sm:top-20 lg:top-32 w-full">
              <div className="flex justify-center sm:justify-end">
                <div className="sm:w-2/3 pl-6 pr-2 sm:pl-4 sm:pr-4">
                  <div className="text-white text-center">
                    <h1 className="font-restaurant uppercase text-6xl sm:text-7xl md:text-9xl lg:text-12xl title-heading-oj"
                      style={{ lineHeight: "0.85" }}>Welcome to your place of happiness</h1>
                      <p className="mb-10 font-brandon max-w-xl mx-auto text-sm lg:text-base">Join Our Discord to get involved in the project.</p>
                    <div className="bg-pink w-60 mx-auto" style={{ marginTop: "1rem" }}><a
                      href="https://discord.gg/FX2cSvQ36h" target="_blank" rel="noreferrer"
                      className="uppercase text-xs tracking-wider h-10 px-6 flex justify-center items-center text-white"><span>JOIN DISCORD</span></a></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute top-header sm:top-auto sm:bottom-20 lg:bottom-32 w-full" id="features">
              <div className="flex justify-center sm:justify-start">
                <div className="sm:w-2/3 px-4">
                  <div className="text-white text-center">
                    <h1 className="font-restaurant uppercase text-4xl lg:text-7xl">Join the project</h1>
                    <p className="mb-10 font-brandon max-w-xl mx-auto text-sm lg:text-base">Orange Juice World was founded in 2018 to spread love and positivity through the use of Music, Films, Fashion, Art, Video Games and in person Music &amp; Art festivals.
                     Our Genesis NFT collection will include our first musical album featuring 8 songs created and performed by our main character ORANGE | JUICE, a teen lover boy in search of his queen.</p>
                     <a href="https://discord.gg/FX2cSvQ36h"
                        className="px-16 py-3 border text-lg font-bebas uppercase text-white border-white" target="_blank"
                        rel="noreferrer">Join us on Discord</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="artist" style={{ backgroundImage: `url(${image16})`, backgroundSize: "cover" }}>
            <div className="max-w-5xl mx-auto py-20 px-4">
              <div className="pt-10 grid grid-cols-1 sm:grid-cols-2 items-center space-x-4 space-y-10">
                <div className="relative mx-auto">
                  <div className="absolute bottom-3 left-3">
                  </div><img className="w-96 h-auto" src={image23} alt="" />
                </div>
                <div className="text-white">
                  <h2 className="gradient-title font-restaurant uppercase text-7xl">The Artist</h2>
                  <p className="my-8 text-lg font-brandon"><strong>Orange | Juice</strong> has been a singer to music producer and songwriter for the last 20+ years, working with many top 100 artists.  After suffering two heart attacks he realized that tomorrow is not promised,
                   today he is determined to build something that can live forever. Our first character is Orange | Juice, a teen lover boy who loves to sing, dance and always finds a way to get into trouble.
                  </p>
                </div>
              </div>
              <div className="mt-5">
                <h2 className="text-center gradient-title font-restaurant uppercase text-7xl">Our team</h2>
                <div className="mt-5 grid grid-cols-2 md:grid-cols-7 justify-center">
                  <div className="mx-4 text-center text-white"><img className="h-auto w-full" src={image38}
                    alt="" />
                    <h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Orange | Juice </h1>
                    <h4 className="text-sm">Artist / Entrepreneur</h4>
                  </div>
                  <div className="mx-4 text-center text-white"><img className="h-auto w-full"
                    src={image39} alt="" />
                    <h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Digitaljeff</h1>
                    <h4 className="text-sm">Project Manager</h4>
                  </div>
                  <div className="mx-4 text-center text-white"><img className="h-auto w-full"
                    src={image26} alt="" />
                    <h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Ben Murray</h1>
                    <h4 className="text-sm">CTO</h4>
                  </div>
                  <div className="mx-4 text-center text-white"><img className="h-auto w-full"
                    src={image40} alt="" />
                    <h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Carlos Ramirez</h1>
                    <h4 className="text-sm">Art Director</h4>
                  </div>
                  <div className="mx-4 text-center text-white"><img className="h-auto w-full"
                    src={image41} alt="" />
                    <h1 className="mt-4 text-xl font-bebas font-semibold uppercase">D'Carlo Calderon</h1>
                    <h4 className="text-sm">Community Manager</h4>
                  </div>
                  <div className="mx-4 text-center text-white"><img className="h-auto w-full"
                    src={image42} alt="" />
                    <h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Paul Mcgee</h1>
                    <h4 className="text-sm">Music &amp; Art Festival Advisor </h4>
                  </div>
                  <div className="mx-4 text-center text-white"><img className="h-auto w-full"
                    src={image43} alt="" />
                    <h1 className="mt-4 text-xl font-bebas font-semibold uppercase">Rolando Sanchez</h1>
                    <h4 className="text-sm">Music Festival Collaborations</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <img src={image11} alt="background"
              style={{ position: "absolute", right: "0px", top: "5rem", zIndex: "0" }} />
            <div id="roadmap"
              style={{ background: "linear-gradient(90deg, rgb(222 229 65) 0%, rgb(97 229 57) 48.96%, rgb(0 67 244) 94.27%) 0% 0% / cover" }}>
              <div className="mx-auto py-20 px-2 sm:px-6 lg:px-8">
                <div className="text-center text-white">
                  <h2 className="font-restaurant font-legendary">THE ORANGE JUICE ALBUM</h2>
                </div>
              </div>
              <div class="legends_slider__wrap">
                <Slider {...settings}>
                  <div className='p-1'>
                    <img src={image31} alt="No image" />
                  </div>
                  <div className='p-1'>
                    <img src={image32} alt="No image" />
                  </div>
                  <div className='p-1'>
                    <img src={image33} alt="No image" />
                  </div>
                  <div className='p-1'>
                    <img src={image34} alt="No image" />
                  </div>
                  <div className='p-1'>
                    <img src={image35} alt="No image" />
                  </div>
                  <div className='p-1'>
                    <img src={image36} alt="No image" />
                  </div>
                  <div className='p-1'>
                    <img src={image37} alt="No image" />
                  </div>
                </Slider>
                <div className='slider-num'>
                  <span class="pre-arrow"><img src={arrowLeft} /></span>
                  {activeSlide2}/7
                  <span class="next-arrow"><img src={arrowRight} /></span>
                </div>
              </div>
              <div className="roadmap--list-wrapper">
                <div className="sc-jRQBWg iNOApb rec rec-carousel-wrapper " style={{ marginTop: "3rem" }}>

                </div>
              </div>
              <div className="gradient-slider pt-2 pb-1">
                <div className=" marquee-container marquee-new">
                  <div className="bg-bar pt-2 pb-1">
                    <div className=" marquee">
                      <div className="marquee-content">
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                      </div>
                    </div>
                  </div>
        
                </div>
              </div>
              <div id="roadmap" style={{ backgroundSize: "cover", background: '#0043F4' }}>
                <div className="mx-auto py-20 px-2 sm:px-6 lg:px-8">
                  <div className="max-w-xl mx-auto text-center text-white">
                    <h2 className="gradient-title font-restaurant text-7xl">Roadmap</h2>
                    <p className="font-brandon">Our roadmap will be updated according to the project's evolution.</p>
                  </div>
                  <div className="max-w-4xl mx-auto mt-16 flex justify-center space-x-2 sm:space-x-4 sm:space-x-8">
                    <div className="flex-shrink-0"><img className="hidden sm:block" src={image20} alt=""
                      style={{ height: "1022.4px", width: "164px" }} /><img className="sm:hidden"
                        src={image20} alt="" style={{ height: "766.8px", width: "92.25px" }} /></div>
                    <div className="roadmap-info">
                      <div className="sm:px-4 sm:px-0">
                        <div className="h-26 sm:h-36">
                          <div className="h-full flex items-center max-w-4xl mx-auto">
                            <div className="text-left">
                              <h3 className="leading-none sm:leading-normal font-bebas text-white sm:text-3xl uppercase">Genesis NFT Collection -  Official date to be announced</h3>
                            </div>
                          </div>
                        </div>
                        <div className="h-26 sm:h-36">
                          <div className="h-full flex items-center max-w-4xl mx-auto">
                            <div className="text-left">
                              <h3 className="leading-none sm:leading-normal font-bebas text-white sm:text-3xl uppercase">Official Orange Juice Merch Drop</h3>
                            </div>
                          </div>
                        </div>
                        <div className="h-26 sm:h-36">
                          <div className="h-full flex items-center max-w-4xl mx-auto">
                            <div className="text-left">
                              <h3 className="leading-none sm:leading-normal font-bebas text-white sm:text-3xl uppercase">New Music - 1 New Song per month (Free Airdrop)</h3>
                            </div>
                          </div>
                        </div>
                        <div className="h-26 sm:h-36">
                          <div className="h-full flex items-center max-w-4xl mx-auto">
                            <div className="text-left">
                              <h3 className="leading-none sm:leading-normal font-bebas text-white sm:text-3xl uppercase">Free Music NFTs  Airdrop for all holders.</h3>                             
                            </div>
                          </div>
                        </div>
                        <div className="h-26 sm:h-36">
                          <div className="h-full flex items-center max-w-4xl mx-auto">
                            <div className="text-left">
                              <h3 className="leading-none sm:leading-normal font-bebas text-white sm:text-3xl uppercase">Orange | Juice Comic Book Series Launch</h3>
                            </div>
                          </div>
                        </div>
                        <div className="h-26 sm:h-36">
                          <div className="h-full flex items-center max-w-4xl mx-auto">
                            <div className="text-left">
                              <h3 className="leading-none sm:leading-normal font-bebas text-white sm:text-3xl uppercase">Orange Juice Art Festival in Miami</h3>
                            </div>
                          </div>
                        </div>
                        <div className="h-26 sm:h-36">
                          <div className="h-full flex items-center max-w-4xl mx-auto">
                            <div className="text-left">
                              <h3 className="leading-none sm:leading-normal font-bebas text-white sm:text-3xl uppercase">Official Orange Juice World  Music Festival in Miami (NFT token Holders will be able to come into the festival for free.)</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div className="gradient-slider pt-2 pb-1">
                <div className="marquee-container marquee-new">
                  <div className="bg-bar pt-2 pb-1">
                    <div className=" marquee">
                      <div className="marquee-content">
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                        <h6 className="font-restaurant text-2xl leading-none mx-1 slider-text">- Join Our Discord, first 200 will get WL -</h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="marquee">
                  </div> */}
                </div>
              </div>


              <div className="bg-black py-6" id="faq">
                <h1 className="text-6xl text-white text-center font-restaurant">FAQ</h1>
                <div className="mt-6 max-w-4xl mx-auto px-4">
                  <div className="hidden md:flex justify-center space-x-4 sm:space-x-10">
                    <div className="w-full">
                      <div className="dl">
                        <div className="border-t border-r border-l border-gray-500 ">
                          <ul id='gradfaq1' onClick={faqButton2}>
                            <button onClick={faqButton1}
                              className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                              id="headlessui-disclosure-button-3" type="button" aria-expanded="false"><span
                                className="font-bebas text-xl text-white">How Can I use my NFT?</span>
                              <span className="ml-2 sm:ml-6 h-7 flex items-center"><svg xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                                className="h-6 w-6 text-white">
                                <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                  d="M12 4v16m8-8H4" ></path>
                              </svg></span>
                            </button>
                            <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-4">
                              <p className="font-brandon text-left text-base text-white">Your NFT will give you access to the 2023 Orange Juice World Music Festival. One Ticket per NFT will be redeemable.</p>
                            </li>
                          </ul>
                        </div>
                        <div className="border-t border-r border-l border-b border-gray-500 ">
                          <ul id='gradfaq2' onClick={faqButton2}><button onClick={faqButton1}
                            className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                            id="headlessui-disclosure-button-5" type="button" aria-expanded="false"><span
                              className="font-bebas text-xl text-white">When will the public mint be held?</span>
                            <span className="ml-2 sm:ml-6 h-7 flex items-center"><svg xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                              className="h-6 w-6 text-white">
                              <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                d="M12 4v16m8-8H4"></path>
                            </svg></span>
                          </button>
                            <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-5">
                              <p className="font-brandon text-left text-base text-white">The public mint date will be announced in our discord, with at least 2 weeks of notice.</p>
                            </li></ul>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="dl">
                        <div className="border-t border-r border-l border-gray-500">
                          <ul id='gradfaq4' onClick={faqButton2}>
                            <button onClick={faqButton1}
                              className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                              id="headlessui-disclosure-button-9" type="button" aria-expanded="false"><span
                                className="font-bebas text-xl text-white">What is the Mint Price?</span>
                              <span className="ml-2 sm:ml-6 h-7 flex items-center"><svg xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                                className="h-6 w-6 text-white">
                                <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                  d="M12 4v16m8-8H4" ></path>
                              </svg></span>
                            </button>
                            <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-7">
                              <p className="font-brandon text-left text-base text-white">Mint Price will be .08 ETH</p>
                            </li>
                          </ul>
                        </div>
                        <div className="border-t border-r border-l border-b border-gray-500 ">
                          <ul id='gradfaq5' onClick={faqButton2}><button onClick={faqButton1}
                            className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                            id="headlessui-disclosure-button-11" type="button" aria-expanded="false"><span
                              className="font-bebas text-xl text-white">Where Can I see my NFT?</span>
                            <span className="ml-2 sm:ml-6 h-7 flex items-center"><svg xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                              className="h-6 w-6 text-white">
                              <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                d="M12 4v16m8-8H4"></path>
                            </svg></span>
                          </button>
                            <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-8">
                              <p className="font-brandon text-left text-base text-white">Once the public sale is over, we will have an official reveal date. All the artwork will be 100% complete before the public mint.</p>
                            </li></ul>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="md:hidden flex justify-center space-x-4 sm:space-x-10">

                    <div className="w-full">
                      <div className="dl">
                        <div className="border-t border-r border-l border-gray-500 ">
                          <ul id='gradfaq1-mob' onClick={faqButton2}>
                            <button onClick={faqButton1}
                              className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                              id="headlessui-disclosure-button-3-mob" type="button" aria-expanded="false"><span
                                className="font-bebas text-xl text-white">How Can I use my NFT?</span>
                              <span className="ml-2 sm:ml-6 h-7 flex items-center"><svg xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                                className="h-6 w-6 text-white">
                                <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                  d="M12 4v16m8-8H4" ></path>
                              </svg></span>
                            </button>
                            <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-4-mob">
                              <p className="font-brandon text-left text-base text-white">Your  NFT will give you access to the 2023 Orange Juice World Music Festival. One Ticket per NFT will be redeemable.</p>
                            </li>
                          </ul>
                        </div>
                        <div className="border-t border-r border-l border-gray-500 ">
                          <ul id='gradfaq2-mob' onClick={faqButton2}><button onClick={faqButton1}
                            className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                            id="headlessui-disclosure-button-5-mob" type="button" aria-expanded="false"><span
                              className="font-bebas text-xl text-white">When will the public mint be held?</span>
                            <span className="ml-2 sm:ml-6 h-7 flex items-center"><svg xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                              className="h-6 w-6 text-white">
                              <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                d="M12 4v16m8-8H4"></path>
                            </svg></span>
                          </button>
                            <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-5-mob">
                              <p className="font-brandon text-left text-base text-white">The public mint date will be announced in our discord, with at least 2 weeks of notice.</p>
                            </li></ul>
                        </div>
                        <div className="border-t border-r border-l border-gray-500  border-b">
                          <ul id='gradfaq3-mob' onClick={faqButton2}><button onClick={faqButton1}
                            className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                            id="headlessui-disclosure-button-7-mob" type="button" aria-expanded="false"><span
                              className="font-bebas text-xl text-white">What is the Mint Price?</span><span className="ml-2 sm:ml-6 h-7 flex items-center"><svg
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                aria-hidden="true" className="h-6 w-6 text-white">
                                <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                  d="M12 4v16m8-8H4"></path>
                              </svg></span></button>
                            <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-6-mob">
                              <p className="font-brandon text-left text-base text-white">Mint Price will be .08 ETH</p>
                            </li></ul>
                        </div>
                      </div>

                      <div className="w-full">
                        <div className="dl">
                          <div className="border-t border-r border-l border-b border-gray-500">
                            <ul id='gradfaq4-mob' onClick={faqButton2}>
                              <button onClick={faqButton1}
                                className="py-4 px-4 sm:px-8 text-left w-full flex justify-between items-start text-gray-400"
                                id="headlessui-disclosure-button-9-mob" type="button" aria-expanded="false"><span
                                  className="font-bebas text-xl text-white">Where Can I see my NFT?</span>
                                <span className="ml-2 sm:ml-6 h-7 flex items-center"><svg xmlns="http://www.w3.org/2000/svg"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                                  className="h-6 w-6 text-white">
                                  <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2"
                                    d="M12 4v16m8-8H4" ></path>
                                </svg></span>
                              </button>
                              <li className="pb-4 px-8 mystyle-faq-1" id="headlessui-disclosure-panel-7-mob">
                                <p className="font-brandon text-left text-base text-white">Once the public sale is over, we will have an official reveal date. All the artwork will be 100% complete before the public mint.</p>
                              </li>
                            </ul>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative bg-black pt-12">
                <div className="h-64" style={{ backgroundImage: `url(${image21})`, backgroundSize: "cover" }}>
                  <div className="h-full flex items-center">
                    <div className="w-full sm:w-2/3 text-white text-center">
                      <h1 className="text-6xl font-restaurant uppercase">Join us on discord</h1>
                      <div className="mb-8">
                        <p className="max-w-lg mx-auto font-brandon text-sm px-4">
                          Be part of an ever-growing community of art collectors, investors and entrepreneurs. Join our
                          Discord and keep up with the Bunny Buddies.</p>
                      </div><a href="https://discord.gg/FX2cSvQ36h"
                        className="px-16 py-3 border-2 font-bebas uppercase text-white" target="_blank" rel="noreferrer">Join
                        us now</a>
                    </div>
                  </div>
                  <div><img className="hidden sm:block absolute bottom-0 right-0 h-48 lg:h-72"
                    src={image22} alt=""/></div>
                </div>
              </div>
              <footer className="bg-black font-brandon">
                <div className="max-w-7xl mx-auto px-4 overflow-hidden sm:px-6 lg:px-8">
                  <div className="py-6 flex items-center justify-between border-b-2 border-gray-700 footer-mob">
                    <div className="w-1/2 sm:w-1/3 flex space-x-4 sm:space-x-8 text-white"
                      style={{ zIndex: "100", position: "relative" }}><a href="https://discord.gg/FX2cSvQ36h" target="_blank"
                        rel="noreferrer">Discord</a><a href="https://www.instagram.com/orangejuice_pr/" target="_blank"
                          rel="noreferrer">Instagram</a><a href="https://twitter.com/orangejuicehere/" target="_blank"
                            rel="noreferrer">Twitter</a></div><img className="hidden sm:block h-12 mx-auto text-white"
                                src={image1} alt="" />
                    <div className="w-1/2 sm:w-1/3 flex space-x-4 sm:space-x-8 justify-end text-white">
                      <a href="/mint">MINT</a>
                      <a href="/terms">Terms of Use</a>
                    </div>
                  </div>
                  <div className="pt-3 pb-5 flex items-center justify-between">
                    <div className="hidden md:block w-1/3 text-gray-700"></div><img className="h-12 mx-auto text-white"
                      src={image12} alt="" />
                    <div className="w-1/3 text-gray-700 text-right text-xs sm:text-base">© 2022 Orange Juice World. All rights
                      reserved</div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
